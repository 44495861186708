import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import apiHelper from "../../Common/ApiHelper"
import Path from "../../Common/Path"

export default function OrderDetailsScreen() {
    const { id } = useParams()
    const [OrderDetails, setOrderDetails] = useState({})
    const [OrderTotal, setOrderTotal] = useState({
        totalItems: 0,
        totalPrice: 0,
        discount: 0,
        subTotal: 0,
        priceSymbole: ""
    })

    async function FetchOrderDetails() {
        try {
            const result = await apiHelper.getOrderById(id)
            let products = result.data.data.products
            let totalItems = 0
            let totalPrice = 0
            let discount = 0
            let priceSymbole = ""
            for (let i = 0; i < products.length; i++) {
                let product = products[i]
                totalItems += product.qty
                totalPrice += (product.qty * product.price.value)
                discount += (product.qty * product.discount.value)
                priceSymbole = product.price.currency.symbol
            }


            setOrderTotal({
                totalItems: totalItems,
                totalPrice: totalPrice,
                discount: discount,
                priceSymbole: priceSymbole,
                subTotal: result?.data?.data?.totalPrice
            })
            setOrderDetails(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        FetchOrderDetails()
        // eslint-disable-next-line
    }, [])
    return <>
        <div className="container width">
            <div className="row">
                <div className="col-12 col-md-7 p-0 my-2">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", color: " rgb(86, 45, 11)" }}>Traking Id : {OrderDetails._id}</h5>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-7 mb-2 ps-md-0" >
                    {
                        OrderDetails && OrderDetails.products && OrderDetails.products.map((x) => {
                            return <div key={x._id} className="row p-2">
                                <div className="col-12 col-md-5 text_primary my-1 p-3">
                                    <Link to={Path.product.split(":")[0] + x._id}>
                                        <img className="cart_item_img w-sm-100" src={OrderDetails.url + x?.image?.find((item) => item.type === "main").path} alt={""} width={"96%"} />
                                    </Link>
                                </div>
                                <div className="col-12 col-md-7 text_primary my-1">
                                    <div className="d-flex mt-3 justify-content-between w-100 align-items-center">
                                        <h5 className="title" style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                            <Link style={{ color: "inherit", textDecoration: "none" }} to={Path.product.split(":")[0] + x._id}>
                                                {x.title}
                                            </Link>
                                        </h5>
                                    </div>
                                    <span className="fs-5 fw-bold" style={{ fontWeight: "300" }}>
                                        {
                                            x.price.currency.symbol
                                            + " " +
                                            x.price.value
                                        }
                                    </span>
                                    <br />
                                    <span className="fs-6" style={{ fontWeight: "300" }}>
                                        All issue easy returns allowed
                                    </span>
                                    <br />
                                    <span className="fs-5 mt-2" style={{ fontWeight: "500" }}>
                                        Qty: &nbsp; {x.qty}
                                    </span>
                                </div>

                            </div>
                        })
                    }

                </div>
                <div className="col-12 col-md-5 my-3">
                    <div className=" text_primary mb-2 w-100">
                        <div className="card-body">
                            <h5 className="fw-bold mb-0">{OrderDetails?.address?.fullName}</h5>
                            <p className="my-2 fw-normal text-muted">
                                {OrderDetails?.address?.address + "," + OrderDetails?.address?.city} <br />
                                {OrderDetails?.address?.state} - {OrderDetails?.address?.pincode} <br />
                                {OrderDetails?.address?.phone}
                            </p>
                        </div>
                    </div>
                    <div className="mb-2 w-100  text_primary">
                        <div className="d-flex align-items-start gap-3 p-3 fw-bold">
                            <div style={{ width: "100%" }}>
                                {OrderDetails?.paymentMethod?.toUpperCase()}
                            </div>
                        </div>
                    </div>
                    <div className="card mb-2 w-100 text_primary">
                        <div className="card-body">
                            <h5>Price Details</h5>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Items</h6>
                                <h6>{OrderTotal.totalItems}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Product Price</h6>
                                <h6>+ {OrderTotal.priceSymbole + OrderTotal.totalPrice}</h6>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Discount</h6>
                                <h6>- {OrderTotal.priceSymbole + OrderTotal.discount}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h5>Order Total</h5>
                                <h5>+ {OrderTotal.priceSymbole + OrderTotal.subTotal}</h5>
                            </div>
                            <a download="/src/logo.svg" href="/src/logo.svg" className="btn bg_primary bg_hover fw-bold w-100 py-2 mt-2" >Download Invoice</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}