import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Path from './Common/Path';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import ProductDetailsScreen from './Screens/ProductDetailsScreen/ProductDetailsScreen';
import RegisterScreen from './Screens/RegisterScreen/RegisterScreen';
import LoginScreen from './Screens/LoginScreen/LoginScreen';
import CartScreen from './Screens/CartScreen/CartScreen';
import CheckOutScreen from './Screens/CheckOutScreen/CheckOutScreen';
import AddressScreen from './Screens/AddressScreen/AddressScreen';
import PaymentScreen from './Screens/PaymentScreen/PaymentScreen';
import OrderDetailsScreen from './Screens/OrderDetailsScreen/OrderDetailsScreen';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import apiHelper from './Common/ApiHelper';

function App() {

  function JWTDECODE(arg) {
    try {
      return jwtDecode(arg)
    } catch (error) {
      return null
    }
  }

  const [Auth, setAuth] = useState(localStorage.getItem("token"))
  const [userInfo, setuserInfo] = useState(JWTDECODE(Auth))
  const [CartItems, setCartItems] = useState([])
  const [CartTotalDetails, setCartTotalDetails] = useState({
    totalPrice: 0,
    totalItems: 0,
    priceSymbole: ""
  })

  async function FetchUserCart(id) {
    try {
      if (!id) return
      const result = await apiHelper.CartList(id)
      setCartItems(result.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (Auth) {
      FetchUserCart(JWTDECODE(Auth)?._id)
      setuserInfo(JWTDECODE(Auth))
    } else {
      if (localStorage.getItem("token")) {
        setuserInfo(JWTDECODE(localStorage.getItem("token")))
        setAuth(localStorage.getItem("token"))
      }
      setuserInfo(null)
    }

  }, [Auth])

  useEffect(() => {
    if (CartItems) {
      let totalPrice = 0
      let totalItems = 0
      for (let i = 0; i < CartItems.length; i++) {
        let totalProductPrice = CartItems[i].product.price.value * CartItems[i].qty
        totalPrice += totalProductPrice
        totalItems += CartItems[i].qty
      }

      setCartTotalDetails({
        totalPrice: totalPrice,
        totalItems: totalItems,
        priceSymbole: CartItems[0] && CartItems[0].product && CartItems[0].product.price ? CartItems[0].product.price.currency.symbol : ""
      })
    }
  }, [CartItems])

  return <BrowserRouter>
    <Header setAuth={setAuth} setCartItem={setCartItems} />
    <main style={{ minHeight: "84.41vh" }}>
      <Routes>
        <Route path={Path.home} element={<HomeScreen />} />
        <Route path={Path.product} element={<ProductDetailsScreen FetchUserCart={FetchUserCart} userInfo={userInfo} CartItems={CartItems} />} />
        <Route path={Path.register} element={<RegisterScreen setAuth={setAuth} Auth={Auth} />} />
        <Route path={Path.login} element={<LoginScreen setAuth={setAuth} Auth={Auth} />} />
        <Route path={Path.cart} element={<CartScreen userInfo={userInfo} CartItems={CartItems} CartTotalDetails={CartTotalDetails} FetchUserCart={FetchUserCart} />} />
        <Route path={Path.checkout} element={<CheckOutScreen userInfo={userInfo} Auth={Auth} CartItems={CartItems} setCartItems={setCartItems} CartTotalDetails={CartTotalDetails} FetchUserCart={FetchUserCart} />} />
        <Route path={Path.address} element={<AddressScreen CartItems={CartItems} CartTotalDetails={CartTotalDetails} userInfo={userInfo} Auth={Auth} />} />
        <Route path={Path.payment} element={<PaymentScreen CartItems={CartItems} CartTotalDetails={CartTotalDetails} />} />
        <Route path={Path.orderDetails} element={<OrderDetailsScreen />} />
      </Routes>
    </main>
    <Footer />
  </BrowserRouter>
}

export default App;
