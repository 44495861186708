const Path = {
    home:"/",
    product:"/product/:id",
    login:"/login",
    register:"/register",
    cart:"/cart",
    address:"/address",
    payment:"/payment",
    checkout:"/checkout",
    orderlist:"/order",
    orderDetails:"/order/:id",
}

export default Path