import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Path from "../../Common/Path"
import apiHelper from "../../Common/ApiHelper"

export default function PaymentScreen({ CartItems, CartTotalDetails }) {
    const navigate = useNavigate()
    const location = useLocation()
    let ProductId = location.search?.split("?")[1]?.split("&")[1]?.split("=")[1]
    let addressId = location.search?.split("?")[1]?.split("&")[0]?.split("=")[1]
    const [Address, setAddress] = useState({})
    const [Product, setProduct] = useState({})
    const [PaymentMethod, setPaymentMethod] = useState("cod")

    useEffect(() => {
      if (!addressId) {
        navigate(Path.address)
      }
      if (!ProductId && (CartItems)) {
        if (CartItems.length <= 0) {
            navigate(Path.home)
        }
      }
      // eslint-disable-next-line
    }, [])
    
    async function getProduct(){
        try {
            const result = await apiHelper.getOrderById(ProductId)
            setProduct(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function getAddress(){
        try {
            const result = await apiHelper.getAddressById(addressId)
            setAddress(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
      if(ProductId){
        getProduct()
      }
      if (addressId) {
        getAddress()
      }
      // eslint-disable-next-line
    }, [])
    

    async function ClickToContiue(){
        if (Product._id) {
            navigate(Path.checkout + "?address=" + Address._id + "&paymentmethod=" + PaymentMethod + "&product=" + Product._id) 
        }else {
            navigate(Path.checkout + "?address=" + Address._id + "&paymentmethod=" + PaymentMethod)
        }

    }
    return <>
        <div className="container w-75">
            <div className="row">
                <div className="col-12 col-md-7 p-0">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", color:" rgb(86, 45, 11)" }} className="my-3">Select Payment Method</h5>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-12 col-md-7 mb-2 ps-md-0">

                    <label className="card p-3 d-block mb-2 w-100 text_primary" style={{ cursor: "pointer", border: "1px solid rgb(86, 45, 11)" }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start gap-3">
                                <div style={{ width: "20px" }}>
                                    <input defaultChecked={true} type="radio" className="form-check-input " />
                                </div>
                                <div style={{ width: "calc(100% - 20px)" }}>
                                    <h5 className="fw-bold mb-0">{Address.fullName}</h5>
                                    <p className="my-2 fw-normal text-muted">
                                        {Address.address + "," + Address.city} <br />
                                        {Address.state} - {Address.pincode} <br />
                                        {Address.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </label>

                    <label className="card p-3 d-block mb-2 w-100" style={{ cursor: "pointer", background: PaymentMethod === "online" ? "rgb(53, 8, 8)" : "white", border: PaymentMethod === "online" ? "black" : undefined, color: PaymentMethod === "online" ? "white" : "black" }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start gap-3">
                                <div style={{ width: "20px" }}>
                                    <input checked={PaymentMethod === "online"} onChange={(e) => setPaymentMethod(e.target.value)} value={"online"} type="radio" className="form-check-input " />
                                </div>
                                <div style={{ width: "calc(100% - 20px", fontWeight:'500' }}>
                                    Online
                                </div>

                            </div>
                        </div>
                    </label>
                    <label className="p-3 d-block mb-2 w-100 text_primary" style={{ cursor: "pointer", background: PaymentMethod === "cod" ? "rgb(53, 8, 8)" : "white", border: PaymentMethod === "cod" ? "white" : undefined, color: PaymentMethod === "cod" ? "white" : "black" }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start">
                                <div style={{ width: "20px" }}>
                                    <input checked={PaymentMethod === "cod"} type="radio" className="form-check-input " value={"cod"} onChange={(e) => setPaymentMethod(e.target.value)} />
                                </div>
                                <div style={{ width: "calc(100% - 20px", fontWeight:'500' }}>
                                    Case On Delivery
                                </div>

                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-5 mb-2">
                    <div className="w-100 text_primary">
                        <div className="card-body">
                            <h5>Price Details</h5>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Items</h6>
                                <h6>+ {Product._id ? 1 : CartTotalDetails.totalItems}</h6>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Product Price</h6>
                                <h6>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value : CartTotalDetails.totalPrice}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h5>Order Total</h5>
                                <h5>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value : CartTotalDetails.totalPrice}</h5>
                            </div>
                            <small style={{ fontSize: "12px", display: "block" }} className="my-2 text-muted text-center">Clicking on ‘Continue’ will not deduct any money</small>
                            <button onClick={ClickToContiue} className="btn bg_primary fw-bold w-100 bg_hover" >Continue</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}