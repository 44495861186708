import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Path from "../../Common/Path"
import apiHelper from "../../Common/ApiHelper"

export default function CartScreen({ CartItems, CartTotalDetails, FetchUserCart, userInfo }) {
    const navigate = useNavigate()
    useEffect(() => {
        if (userInfo && userInfo._id) {
            FetchUserCart(userInfo._id)
        }
        // eslint-disable-next-line
    }, [])


    async function QtyHandler(product, qty) {
        try {
            if (!userInfo) return navigate(Path.login)
            const cartProduct = {
                user: userInfo._id,
                product: product,
                qty: qty
            }
            await apiHelper.addToCart(cartProduct)
            FetchUserCart(userInfo._id)
        } catch (error) {
            console.log(error);
        }
    }


    async function ClickToContinue() {
        navigate(Path.address)
    }


    async function removeHandler(id) {
        try {
            if (!userInfo) return navigate(Path.login)
            await apiHelper.removeToCart(id)
            FetchUserCart(userInfo._id)
        } catch (error) {
            console.log(error);
        }
    }
    return <>
        {
            CartItems && CartItems.length > 0 ? <div className="container">
                <div className="row p-4">
                    <div className="col-12 col-md-7">
                        {
                            CartItems && CartItems.map((x) => {
                                return <div key={x.product._id} className="cart_body text_primary">
                                    <div className="row d-flex justify-content-md-center">
                                        <div className="col-md-5 col-12">
                                            <div className="Prod_img p-2">
                                                <Link to={`/product/${x.product._id}`}>
                                                    <img className="img_hover" src={x?.product?.image?.find((x) => x.type === "main").uri} alt="product" width={"200px"} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-12 p-2">
                                            <div className="d-flex justify-content-end" style={{width:"96%"}}>
                                                <div onClick={() => removeHandler(x._id)} className="btn bg_primary bg_hover qtybutton"><i className="fa-solid fa-xmark fw-bold"></i></div>
                                            </div>
                                            <div className="Prod_Details my-2">
                                                <Link to={`/product/${x.product._id}`} className="text-decoration-none h5 w-100 text_primary">
                                                    <div className="text_hover">{x.product.title}</div>
                                                </Link>
                                                <div className="prod_price fw-bold my-2" style={{fontSize:"18px"}}>
                                                    {x.product.price.currency.symbol} {x.product.price.value}
                                                </div>
                                                <div className="my-2">All issue easy returns allowed</div>
                                                <div className="my-2">
                                                    <span className="h6">Qty : &nbsp;</span>
                                                    <span className="">
                                                        <button disabled={x.qty <= 1} onClick={() => QtyHandler(x.product._id, -1)} className="btn fw-bold qtybutton bg_primary bg_hover"><i className="fa-solid fa-minus"></i></button>
                                                        &nbsp; &nbsp; {x.qty} &nbsp; &nbsp;
                                                        <button disabled={x.qty >= 20} onClick={() => QtyHandler(x.product._id, 1)} className="btn fw-bold qtybutton bg_primary bg_hover"><i className="fa-solid fa-plus"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            })
                        }
                        <div onClick={() => {
                           navigate(Path.home)
                        }} type="button" className="text-center fw-bold" style={{color:" rgb(86, 45, 11)"}}><i className="fa-solid fa-plus"></i> Add more Product in Cart</div>
                    </div>
                    <div className="col-12 col-md-5 text_primary p-3 panel">
                        <div className="h4">Product Details</div><hr />
                        <div className="h5 mt-2 d-flex justify-content-between"><span>Total Items :</span><span>{CartTotalDetails.totalItems}</span></div>
                        <div className="h5 mt-2 d-flex justify-content-between"><span>Product Price :</span><span>{CartTotalDetails.priceSymbole} {CartTotalDetails.totalPrice}</span></div>
                        <hr />
                        <div className="h5 mt-2 d-flex justify-content-between"><span>Total Price :</span><span>{CartTotalDetails.priceSymbole} {CartTotalDetails.totalPrice}</span></div>
                        <hr />
                        <div onClick={ClickToContinue} className="btn bg_primary w-100  bg_hover fw-bold">Countiue</div>
                    </div>
                </div>
            </div>
                : <div className="d-flex justify-content-center align-items-center"> 
                   <div className="mt-3">
                   <h4>View Product :</h4>
                    <button onClick={() => {
                        navigate(Path.home)
                    }} className="btn bg_primary bg_hover fw-bold my-2">Show Product</button>
                   </div>
                </div>
        }
    </>
}