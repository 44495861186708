import axios from "axios"

class ApiHelper {
    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_URL
      
        axios.interceptors.request.use((config) => {
            config.headers["token"] = localStorage.getItem("token")
            return config
        }, (err) => {
            return Promise.reject(err)
        })


         axios.interceptors.response.use((res) => {
             return res
         }, (err) => {
             if (err && err.response && err.response.status === 401 && err.response.data.message === "unAuthorized") {
                 localStorage.removeItem("token")
                 window.location.pathname = "/login"
                 return Promise.reject(err)
             }
             return Promise.reject(err)
         })

    }

    listProduct() {
        return axios.get(this.baseUrl + "/product")
    }

    getProductById(id) {
        return axios.get(this.baseUrl + "/product/" + id)
    }


    userRegister(data) {
        return axios.post(this.baseUrl + "/user/register", data)
    }

    userLogin(data) {
        return axios.post(this.baseUrl + "/user/login", data)
    }


    addToCart(data) {
        return axios.post(this.baseUrl + "/cart", data)
    }

    CartList(user) {
        return axios.get(this.baseUrl + "/cart/" + user)
    }

    removeToCart(id) {
        return axios.delete(this.baseUrl + "/cart/" + id)
    }


    addAddress(data) {
        return axios.post(this.baseUrl + "/address", data)
    }

    listAddress(userId) {
        return axios.get(this.baseUrl + "/address/list/" + userId)
    }

    getAddressById(id) {
        return axios.get(this.baseUrl + "/address/" + id)
    }
    deleteAddress(id) {
        return axios.delete(this.baseUrl + "/address/delete/" + id)
    }


    createOrder(data) {
        return axios.post(this.baseUrl + "/order", data)
    }

    getOrderById(id) {
        return axios.get(this.baseUrl + "/order/" + id)
    }

    paymentVerify(data) {
        return axios.post(this.baseUrl + "/order/payment/verify", data)
    }

    emptyUserCart(id){
        return axios.delete(this.baseUrl + "/cart/empty/" + id)
    }
}


const apiHelper = new ApiHelper()

export default apiHelper