import { useEffect, useState } from "react"
import apiHelper from "../../Common/ApiHelper"
import { Link } from "react-router-dom"

export default function HomeScreen() {
    const [Product, setProduct] = useState([])
    async function getProduct() {
        try {
            const result = await apiHelper.listProduct()
            setProduct(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getProduct()
        // eslint-disable-next-line
    }, [])

    return <>
        <div className="container container_home">
            <div className="row">
                {
                    Product.map((x) => {
                        return <Link to={`/product/${x._id}`} key={x._id} className="col-6 col-md-3 col-sm-4 text-decoration-none my-3 p-1 p-md-3">
                        <div className="card p-md-3 p-0 text_primary bg_hover" style={{width:"100%"}}>
                            <img src={x.url+ x.image.find((x) => x.type === "main").path} alt="" height={"260px"} />
                            <h5 className="my-2">
                                {x.title}
                            </h5>
                            <h5 className="my-1">
                            {x.price && x.price.currency.symbol} {x.price && x.price.value}
                            </h5>
                        </div>
                    </Link>
                    })
                }
            </div>
        </div>
    </>
}