import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "../../Common/Path";
import apiHelper from "../../Common/ApiHelper";

export default function Drawer({ userInfo, open, setOpen, FetchAddress }) {
    const navigate = useNavigate()
    const [Error, setError] = useState("")
    const [AddressDetails, setAddressDetails] = useState({
        fullName: "",
        phone: "",
        house: "",
        area: "",
        city: "",
        state: "",
        pincode: ""
    })



    async function AddressHandler() {
        try {
            if (!userInfo) return navigate(Path.login)
            if (!AddressDetails.fullName) return setError("Required field firstname is Empty")
            if (!AddressDetails.phone) return setError("Required field phone is Empty")
            if (!AddressDetails.city) return setError("Required field city is Empty")
            if (!AddressDetails.state) return setError("Required field state is Empty")
            if (!AddressDetails.area) return setError("Required field area is Empty")
            // eslint-disable-next-line
            if (Number(AddressDetails.phone) === NaN) return setError("Your Phone number is invalid")
            // eslint-disable-next-line
            if (Number(AddressDetails.pincode) === NaN) return setError("Your Pincode is invalid")


            const data = {
                fullName: AddressDetails.fullName,
                user: userInfo._id,
                phone: AddressDetails.phone,
                city: AddressDetails.city,
                state: AddressDetails.state,
                pincode: AddressDetails.pincode,
                address: AddressDetails.house + "," + AddressDetails.area
            }
            await apiHelper.addAddress(data)
            FetchAddress()
            setOpen(false)
        } catch (error) {
            console.log(error.massage);
        }
    }


    useEffect(() => {
        if (userInfo)
            setAddressDetails((A) => {
                return { ...A, fullName: userInfo.fullName, phone: userInfo.Number }
            })
        // eslint-disable-next-line
    }, [userInfo])


    return open ? <>
        <div style={{ position: "fixed", left: "0", top: "0", height: "90vh", width: "100%", zIndex: "500" }}>
            <div onClick={() => setOpen(false)} style={{ backgroundColor: "rgba(214, 198, 159,0.9)", position: "absolute", left: "0", top: "0", height: "100vh", width: "100%", zIndex: "-1" }}></div>
            <div className="container w-75 text_primary p-2" style={{ position: "absolute",top:"2%",left:"10%", zIndex: "1" }}>
                <div className="d-flex justify-content-end align-items-center">
                    <h5 type="button" onClick={() => setOpen(false)} className="text-muted btn bg_primary m-1"><i className="fa-solid fa-xmark"></i></h5>
                </div>
                {
                    Error && <div className="d-flex gap-2 align-items-center alert alet-danger">
                        <i className="fa-solid fa-circle-exclamation"></i>
                        <small className="fw-bold">{Error}</small>
                    </div>
                }
                <div className="container w-75">
                    <h5 className="fw-bold"><i className="fa-solid fa-phone"></i> Contact Details</h5>

                    <input className="form-control" type="text" placeholder="FullName" onChange={(e) => setAddressDetails({ ...AddressDetails, fullName: e.target.value })} value={AddressDetails.fullName} />
                    <br />
                    <input className="form-control" type="text" maxLength={10} placeholder="Contact Number" onChange={(e) => setAddressDetails({ ...AddressDetails, phone: e.target.value })} value={AddressDetails.phone} />
                    <br />

                    <h5 className="fw-bold"><i className="fa-solid fa-map-location-dot"></i> Address</h5>

                    <input className="form-control" type="text" placeholder="House no./ Building Name" onChange={(e) => setAddressDetails({ ...AddressDetails, house: e.target.value })} value={AddressDetails.house} />
                    <br />
                    <input className="form-control" type="text" placeholder="Road Name / Area / Colony" onChange={(e) => setAddressDetails({ ...AddressDetails, area: e.target.value })} value={AddressDetails.area} />
                    <br />
                    <input className="form-control" type="text" maxLength={10} placeholder="Pincode" onChange={(e) => setAddressDetails({ ...AddressDetails, pincode: e.target.value })} value={AddressDetails.pincode} />
                    <br />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <input className="form-control" type="text" placeholder="City" onChange={(e) => setAddressDetails({ ...AddressDetails, city: e.target.value })} value={AddressDetails.city} />
                            <br />
                        </div>
                        <div className="col-12 col-md-6">
                            <input className="form-control" type="text" placeholder="State" onChange={(e) => setAddressDetails({ ...AddressDetails, state: e.target.value })} value={AddressDetails.state} />
                            <br />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                    <button onClick={AddressHandler} className="btn bg_primary fw-bold bg_hover"> Save Address and Countinue</button>
                    </div>
                </div>
            </div>
        </div>
    </>

        : <>
        </>
}