import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Path from "../../Common/Path"
import apiHelper from "../../Common/ApiHelper"


export default function CheckOutScreen({ userInfo, Auth, FetchUserCart, CartItems, setCartItems, CartTotalDetails }) {
    const navigate = useNavigate()
    const location = useLocation()
    let ProductId = location.search?.split("?")[1]?.split("&")[2]?.split("=")[1]
    let addressId = location.search?.split("?")[1]?.split("&")[0]?.split("=")[1]
    let PaymentMethod = location.search?.split("?")[1]?.split("&")[1]?.split("=")[1]
    const [Address, setAddress] = useState({})
    const [Product, setProduct] = useState({})
    const [Error, setError] = useState("")


    useEffect(() => {
        if (!Auth) {
            navigate(Path.login)
        }
        // eslint-disable-next-line
    }, [Auth])

    useEffect(() => {
        if (!PaymentMethod || !addressId) {
            navigate(Path.payment)
        }
        // eslint-disable-next-line
    }, [])


    async function getProduct() {
        try {
            const result = await apiHelper.getOrderById(ProductId)
            result.data.data.qty = 1
            let i = 0
            const data = result.data.data.image

            while (i < data.length) {
                data[i].uri = result.data.data.url + data[i].path
                i++
            }
            setProduct(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function getAddress() {
        try {
            const result = await apiHelper.getAddressById(addressId)
            setAddress(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (ProductId) {
            getProduct()
        }
        if (addressId) {
            getAddress()
        }
        // eslint-disable-next-line
    }, [])


    async function handlePayment(orderDetails) {
        const option = {
            key: orderDetails.razorpayDetails.api_key,
            amount: orderDetails.razorpayDetails.amount,
            currency: orderDetails.razorpayDetails.currency,
            name: "Kunj",
            description: "This Is Payment Procees",
            oreder_id: orderDetails.razorpayDetails.id,
            handler: async (response) => {
                try {
                    const result = await apiHelper.paymentVerify({ ...response, orderId: orderDetails._id })
                    console.log(result.data.data.paymentInfo.phone, userInfo.Number);
                    if(result.data.data && result.data.data.paymentInfo && result.data.data.paymentInfo.email === userInfo.email && result.data.data.paymentInfo.phone.toString() === "+91" + userInfo.Number.toString() ) {
                      return navigate(Path.orderDetails.split(":")[0] + orderDetails._id)
                    } else {
                         navigate(Path.checkout)
                        return setError("Payment Details was Invalid")
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            prefill: {
                name: userInfo.fullName,
                email: userInfo.email,
                contact: userInfo.Number
            },
            theme:{
                color:"rgba(216, 189, 119, 0.858)"
            }
        }

        const rzp = new window.Razorpay(option)
        rzp.open()
    }



    async function PlaceOrder() {
        try {
            if (!userInfo || !userInfo._id) {
                navigate(Path.login)
                return
            }
            const data = {
                user: userInfo._id,
                products: Product._id ? [{ id: Product._id, qty: Product.qty }] : CartItems.map((x) => {
                    return { id: x.product._id, qty: x.qty }
                }),
                paymentMethod: PaymentMethod,
                address: Address._id
            }
            const result = await apiHelper.createOrder(data)
            if (!Product._id && !ProductId) {
                await apiHelper.emptyUserCart(userInfo._id)
                FetchUserCart(userInfo._id)
            }
            if (PaymentMethod === "cod") return navigate(Path.orderDetails.split(":")[0] + result.data.data._id)
            handlePayment(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }


    async function qtyHandler(qty, index) {
        if (Product._id) {
            setProduct({ ...Product, qty: qty })
            return
        }
        const tmp = [...CartItems]
        tmp[index].qty = qty
        setCartItems([...tmp])
    }

    return <>
        <div className="container width">
            <div className="row">
                <div className="col-12 col-md-7 my-2">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", color: "rgb(86, 45, 11)" }}>Check Confirm Your Order</h5>
                        <small className="fw-bold text_danger" style={{minWidth:"0"}}>{Error}</small>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-7 mb-2 ps-md-0" >
                    {

                        Product._id ? (<>
                            <div key={Product._id} className="text_primary mb-2 w-100">
                                <div className="card-body text_primary">
                                    <div className="d-flex flex-wrap ">
                                        <div>
                                            <Link to={Path.product.split(":")[0] + Product._id}>
                                                <img className="cart_item_img w-sm-100" src={Product?.image?.find((x) => x.type === "main").uri} alt={Product.title} width={"96%"}/>
                                            </Link>
                                        </div>
                                        <div style={{ width: "calc(100% - 100px)" }}>
                                            <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                                                <h5 className="title" style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                                    <Link className="h4" style={{ textDecoration: "none" }} to={Path.product.split(":")[0] + Product._id}>
                                                        {Product.title}
                                                    </Link>
                                                </h5>
                                            </div>
                                            <span className="fs-4 fw-bold" style={{ fontWeight: "300" }}>
                                                {
                                                    Product.price.currency.symbol
                                                    + " " +
                                                    Product.price.value
                                                }
                                            </span>
                                            <br />
                                            <span className="fs-6" style={{ fontWeight: "300" }}>
                                                All issue easy returns allowed
                                            </span>
                                            <br />
                                            <span className="fs-4 fw-bold d-inline-block mt-2" style={{ fontWeight: "300" }}>
                                                <button onClick={() => qtyHandler(Product.qty - 1)} disabled={Product.qty <= 1} className="btn bg_primary bg_hover" style={{  fontSize: "14px", border: "none"}}><i className="fa-solid fa-minus"></i></button>
                                                &nbsp; &nbsp;{Product.qty}&nbsp; &nbsp;
                                                <button onClick={() => qtyHandler(Product.qty + 1)} disabled={Product.qty >= 20} className="btn bg_primary bg_hover" style={{ fontSize: "14px", border: "none" }}><i className="fa-solid fa-plus"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>) : <>
                            {
                                CartItems && CartItems.map((x, key) => {
                                    return <div key={x.product._id} className="text_primary mb-2 w-100 container">
                                        <div className="d-flex row p-2">
                                            <div className="col-12 col-md-5">
                                                <Link to={Path.product.split(":")[0] + x.product._id}>
                                                    <img className="cart_item_img w-sm-100" src={x?.product?.image?.find((x) => x.type === "main").uri} alt={x.product.title} width={"100%"} />
                                                </Link>
                                            </div>
                                            <div className="mt-3 col-12 col-md-7">
                                                <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                                                    <h5 className="title" style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                                        <Link style={{ color: "inherit", textDecoration: "none" }} to={Path.product.split(":")[0] + x.product._id}>
                                                            {x.product.title}
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <span className="fs-5 fw-bold" style={{ fontWeight: "300" }}>
                                                    {
                                                        x.product.price.currency.symbol
                                                        + " " +
                                                        x.product.price.value
                                                    }
                                                </span>
                                                <br />
                                                <span className="fs-6" style={{ fontWeight: "300" }}>
                                                    All issue easy returns allowed
                                                </span>
                                                <br />
                                                <span className="fs-6 d-inline-block mt-2" style={{ fontWeight: "300" }}>
                                                    <button onClick={() => qtyHandler(x.qty - 1, key)} disabled={x.qty <= 1} className="btn bg_primary bg_hover" style={{ fontSize: "14px", border: "none" }}><i className="fa-solid fa-minus"></i></button>
                                                    &nbsp; &nbsp;{x.qty}&nbsp; &nbsp;
                                                    <button onClick={() => qtyHandler(x.qty + 1, key)} disabled={x.qty >= 20} className="btn bg_primary bg_hover" style={{ fontSize: "14px", border: "none" }}><i className="fa-solid fa-plus"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </>
                    }

                    <label className="card p-3 d-block mb-2 w-100 text_primary" style={{ cursor: "pointer", border: "1px solid " }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start gap-3">

                                <div style={{ width: " 100%" }}>
                                    <h5 className="fw-bold mb-0">{Address.fullName}</h5>
                                    <p className="my-2 fw-normal w-100 text-dark">
                                        {Address.address + "," + Address.city} <br />
                                        {Address.state} - {Address.pincode} <br />
                                        {Address.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </label>
                    <label className="card p-3 d-block mb-2 w-100 text_primary" style={{ cursor: "pointer", border: "1px solid " }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start gap-3 fw-bold">
                                Payment Method: {PaymentMethod?.toUpperCase()}
                            </div>
                        </div>
                    </label>

                </div>

                <div className="col-12 col-md-5 mb-2 pe-md-0">
                    <div className="card w-100 text_primary">
                        <div className="card-body">
                            <h5>Price Details</h5>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Items</h6>
                                <h6>+ {Product._id ? 1 : CartTotalDetails.totalItems}</h6>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Product Price</h6>
                                <h6>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value * Product.qty : CartTotalDetails.totalPrice}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h5>Order Total</h5>
                                <h5>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value * Product.qty : CartTotalDetails.totalPrice}</h5>
                            </div>
                            <small style={{ fontSize: "12px", display: "block" }} className="my-2 text-muted text-center">Clicking on ‘Continue’ will not deduct any money</small>
                            <button onClick={() => PlaceOrder()} className="btn fw-bold bg_hover bg_primary w-100" >Place Order</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}