import { useLocation, useNavigate } from "react-router-dom";
import apiHelper from "../../Common/ApiHelper";
import { useEffect, useState } from "react";
import Path from "../../Common/Path";
import Drawer from "./Drawer";

export default function AddressScreen({ userInfo, Auth, CartItems, CartTotalDetails }) {
    const navigate = useNavigate()
    const location = useLocation()
    let ProductId = location.search
    ProductId = ProductId ? ProductId.split("?product=")[1] : undefined
    const [Product, setProduct] = useState({})
    const [open, setopen] = useState(false)
    const [AddressList, setAddressList] = useState([])
    const [SelectedAddress, setSelectedAddress] = useState("")

    async function getProduct() {
        try {
            const result = await apiHelper.getProductById(ProductId)
            setProduct(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function FetchAddress() {
        try {
            const result = await apiHelper.listAddress(userInfo._id)
            setAddressList(result.data.data)
            setSelectedAddress(result.data.data[0]._id)
        } catch (error) {
            console.log(error);
        }
    }


    function getPaymentMethod(addressId) {
        if (ProductId) {
            return navigate(Path.payment + "?address=" + addressId + "&product=" + ProductId)
        }
        return navigate(Path.payment + "?address=" + addressId)
    }

    useEffect(() => {
        if (userInfo && userInfo._id) {
            FetchAddress()
            if (ProductId) getProduct()
        } else {
            navigate(Path.login)
        }
        // eslint-disable-next-line
    }, [userInfo])

    return <>
        <Drawer FetchAddress={FetchAddress} userInfo={userInfo} open={open} setOpen={setopen} />
        <div className="container width">
            <div className="row">
                <div className="col-12 col-md-7 p-0">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "50%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Select Delivery Address</h5>
                        <button onClick={() => setopen(true)} className="fw-bold btn" style={{ cursor: "pointer", color: "rgb(86, 45, 11)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>+ ADD NEW ADDRESS</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-7 mb-2 ps-md-0">
                    {
                        AddressList.map((x) => {
                            return <label key={x._id} className="my-2 d-block w-100">
                                <div className="d-flex align-items-start text_primary p-2" style={{ cursor: "pointer", border: x._id === SelectedAddress ? "1px solid rgb(86, 45, 11)" : "none" }}>
                                    <div className="text_primary" style={{ width: "20px" }}>
                                        <input onChange={(e) => setSelectedAddress(e.target.value)} checked={SelectedAddress === x._id} value={x._id} type="radio" className="form-check-input mi1" />
                                    </div>
                                    <div className="" style={{ width: "calc(100% - 20px)" }}>
                                        <h5 className="fw-bold mb-0">{x.fullName}</h5>
                                        <p className="my-2 fw-normal text-muted">
                                            {x.address + "," + x.city} <br />
                                            {x.state} - {x.pincode} <br />
                                            {x.phone}
                                        </p>
                                        {
                                            SelectedAddress === x._id && (
                                                <button onClick={() => getPaymentMethod(x._id)} className="fw-bold btn bg_primary bg_hover w-100 py-2">Deliver to this Address</button>
                                            )
                                        }
                                    </div>
                                </div>
                            </label>

                        })
                    }
                </div>
                <div className="col-12 col-md-5 mb-2 text_primary panel">
                    <div className="w-100 p-3">
                        <h5>Price Details</h5>
                        <div className="d-flex w-100 justify-content-between">
                            <h6>Total Items</h6>
                            <h6>+ {Product._id ? 1 : CartTotalDetails.totalItems}</h6>
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                            <h6>Total Product Price</h6>
                            <h6>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value : CartTotalDetails.totalPrice}</h6>
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap w-100 justify-content-between">
                            <h5>Order Total</h5>
                            <h5>+ {Product.price ? Product.price.currency.symbole : CartTotalDetails.priceSymbole}{Product.price ? Product.price.value : CartTotalDetails.totalPrice}</h5>
                        </div>
                        <small style={{ fontSize: "12px", display: "block" }} className="my-2 text-muted text-center">Clicking on ‘Continue’ will not deduct any money</small>
                    </div>
                </div>
            </div>

        </div>
    </>
}