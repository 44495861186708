import { useEffect, useState } from "react"
import apiHelper from "../../Common/ApiHelper"
import { useNavigate, useParams } from "react-router-dom"
import Path from "../../Common/Path"
import DetailsFooter from "./DetailsFooter"

export default function ProductDetailsScreen({ userInfo, CartItems, FetchUserCart }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [Product, setProduct] = useState({})
    const [CurrentImage, setCurrentImage] = useState({})
    async function getProductId() {
        try {
            const result = await apiHelper.getProductById(id)
            let i = 0
            const data = result.data.data
            let mainImage = {}
            while (i < data.image.length) {
                data.image[i].uri = data.url + data.image[i].path
                if (data.image[i].type === "main") {
                    mainImage = data.image[i]
                }
                i++
            }
            setProduct(data)
            setCurrentImage(mainImage)

        } catch (error) {

        }
    }

    useEffect(() => {
        getProductId()
        // eslint-disable-next-line
    }, [])


    async function CartHandler() {
        try {
            if (!userInfo?._id || !localStorage.getItem("token")) {
                navigate(Path.login)
                return
            }
            const cartProduct = {
                product: id,
                qty: 1,
                user: userInfo?._id
            }
            if (CartItems && CartItems.find((x) => x.product._id === cartProduct.product)) {
                return navigate(Path.cart)
            }
            await apiHelper.addToCart(cartProduct)
            FetchUserCart(userInfo._id)
        } catch (error) {
            console.log(error);
        }
    }

    function BuyNowHandler() {
        return navigate(Path.address + "?product=" + id)
    }

    return <div className="container">
            <div className="row text_primary p-3">
                <div className="col-12 col-md-5 my-4 d-flex align-items-center proScreen_img">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="d-flex flex-md-column flex-row w-100">
                                {
                                    Product && Product?.image?.map((x) => {
                                        return <img className="my-2" key={x._id} onMouseEnter={() => setCurrentImage(x)} style={{ border: CurrentImage._id === x._id ? "2px solid blue" : "none", borderRadius: "10px" }} alt="Product" src={Product.url + x.path} width={"70%"} height={"100%"} />
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-9 col-12" style={{ display: "flex", alignItems: "center" }}>
                            <div className="row">
                                <div className="col-12">
                                    <img src={CurrentImage.uri} alt="" width={"90%"} height={"100%"} />
                                </div>
                                <div className="col-12">
                                    <DetailsFooter CartHandler={CartHandler} BuyNowHandler={BuyNowHandler} CartItems={CartItems} id={id} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 content p-4 my-4">
                    <div className="h4 my-2">{Product.title}</div>
                    <div className="h2 my-2">{Product.price && Product.price.currency.symbol} {Product.price && Product.price.value}</div>
                    <div><span className="btn btn-success my-2 fw-bold">{Product.rating}</span> Rating & &nbsp;<span className="text-secoundary fw-bold">{Product.numReviews}</span> Review</div>
                    <div className="my-3" dangerouslySetInnerHTML={{ __html: Product.content }}></div>
                </div>
            </div>
        </div>
}