import { useNavigate } from "react-router-dom"
import Path from "../Common/Path"

export default function Header({ setCartItem, setAuth }) {
    const token = localStorage.getItem("token")
    const navigate = useNavigate()
    return <>
        <div className="header container-fluid bg_primary p-2">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div onClick={() => {
                        return navigate(Path.home)
                    }} className="col-md-3 col-6 h2" style={{ letterSpacing: "1px" }}>
                        Shopping Lite
                    </div>
                    <div className="col-6 d-none d-md-block text-center">
                        <input type="text" className="form-control" placeholder="Search anything"></input>
                    </div>
                    <div className="col-md-3 col-6 d-flex justify-content-end">
                        <div className="back">
                            {
                                token ? <button onClick={() => {
                                    localStorage.removeItem("token")
                                    setAuth(token)
                                    setCartItem([])
                                    return navigate(Path.home)
                                }} className="btn text_primary fw-bold bg_hover w-100 d-flex justify-content-evenly"><span><i className="fa-solid fa-user"></i></span><span className="d-lg-block d-none"> Log Out</span></button> : <button onClick={() => {
                                    return navigate(Path.login)
                                }} className="btn text_primary fw-bold bg_hover w-100 d-flex justify-content-evenly"><span><i className="fa-solid fa-user"></i></span><span className="d-lg-block d-none"> Sing In</span></button>
                            }
                        </div>
                        <div className="back w-25">
                            <button onClick={() => {
                                if (!localStorage.getItem("token")) {
                                    return navigate(Path.login)
                                }
                                return navigate(Path.cart)
                            }} className="btn text_primary bg_hover fw-bold w-100 d-flex justify-content-evenly"><span><i className="fa-solid fa-cart-shopping"></i></span><span className="d-lg-block d-none"> Cart</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}