export default function DetailsFooter({ CartHandler, CartItems, BuyNowHandler, id }) {


    return <div className="d-flex row my-4 justify-content-between">
        <div className="col-12 col-lg-5 my-2">
            <button onClick={CartHandler} className="btn bg_primary bg_hover fw-bold w-100"><i className="fa-solid fa-cart-shopping"></i> {CartItems.find((x) => x.product._id === id) ? "Go To Cart" : "Add to Cart"}</button>
        </div>

        <div className="col-12 col-lg-5 my-2">
            <button onClick={BuyNowHandler} className="btn bg_primary bg_hover fw-bold w-100"><i className="fa-solid fa-angles-right"></i> Buy Now</button>
        </div>
    </div>
}