import { useEffect, useState } from "react";
import apiHelper from "../../Common/ApiHelper";
import { useNavigate } from "react-router-dom";
import Path from "../../Common/Path";

export default function RegisterScreen() {
    const token = localStorage.getItem("token")
    const navigate = useNavigate()

    const [RegisterDetails, setRegisterDetails] = useState({
        fullName: "",
        email: "",
        Number: "",
        Password: "",
    })

    async function userRegister(e) {
        try {
            e.preventDefault()
            const data = ({ ...RegisterDetails })
            const result = await apiHelper.userRegister(data)
            localStorage.setItem("token", result.data.token)
            navigate(Path.home)
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        if (token) {
            navigate(Path.home)
        }
        // eslint-disable-next-line
    }, [])

    return <>
        <div className="container-fluid login_root p-4">
            <center>
                <form onSubmit={userRegister} className="p-4 form-control text_primary">
                    <div className="h1">Register Your Account</div>
                    <br />
                    <br />
                    <input className="form-control" type="text" id="fullName" onChange={(e) => setRegisterDetails({...RegisterDetails, fullName:e.target.value})} value={RegisterDetails.fullName} placeholder="Enter your FullName" />
                    <br />
                    <br />
                    <input className="form-control" type="email" id="email" onChange={(e) => setRegisterDetails({...RegisterDetails, email:e.target.value})} value={RegisterDetails.email} placeholder="Enter your Email" />
                    <br />
                    <br />
                    <input className="form-control" type="text" id="Number" onChange={(e) => setRegisterDetails({...RegisterDetails, Number:e.target.value})} value={RegisterDetails.Number} placeholder="Enter Phone Number" />
                    <br />
                    <br />
                    <input className="form-control" type="text" id="Password" onChange={(e) => setRegisterDetails({...RegisterDetails, Password:e.target.value})} value={RegisterDetails.Password} placeholder="Enter Password" />
                    <br />
                    <br />
                    <button className="btn bg_primary bg_hover fw-bold">Sing Up</button><br />
                    <br />
                    <button onClick={() => {
                        return navigate(Path.login)
                    }} className="btn bg_primary bg_hover fw-bold">Log In</button>
                </form>
            </center>
        </div>
    </>
}