
export default function Footer() {
    return <>
        <div className="continer-fluid bg_primary p-2 footer">
            <div className="row">
                <div className="col-12 text-center">
                    <small className="text-mutled">Maneged by:</small>
                    <div className="h2">Vempire Street</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 d-flex justify-content-center">
                    <div className="con_link">
                    <div className="h5 my-3"><i className="fa-solid fa-address-book"></i> Contact Info</div><hr />
                    <div className="list-unstyled">
                        <li className="my-2"><i className="fa-solid fa-user"></i> Kunj Patel</li>
                        <li><a className="text-decoration-none bg_primary" href="mailto:“disha@uplers.com?bcc=”kevin@uplers.com?subject=Can%20you%20help%20”"><i className="fa-solid fa-envelope"></i> kunjanpatel163@gmail.com</a></li>
                        <li className="my-2"><i className="fa-solid fa-phone"></i> 95866 84761</li>
                        <div className="con_icon">
                            <a href="https://www.instagram.com/kunj_patel_89" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                            <a href="https://www.linkedin.com/in/kunj-sherathiya-871a93292" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center">
                    <div className="con_help">
                        <div className="h5 my-3"><i className="fa-brands fa-hire-a-helper"></i> Let Us Help You</div><hr />
                        <div className="list-unstyled">
                            <li className="my-2">Your Account</li>
                            <li className="my-2">Center</li>
                            <li className="my-2">Download App</li>
                            <li className="my-2">Help</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}