import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import apiHelper from "../../Common/ApiHelper"
import Path from "../../Common/Path"

export default function LoginScreen() {
    const token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [LoginDetails, setLoginDetails] = useState({
        Number: "",
        Password: ""
    })

    async function userLogin(e) {
        try {
            e.preventDefault()
            const data = ({ ...LoginDetails })
            const result = await apiHelper.userLogin(data)
            localStorage.setItem("token", result.data.token)
            navigate(Path.home)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        if (token) {
            navigate(Path.home)
        }
        // eslint-disable-next-line
    }, [])

    return <>
        <div className="container login_root p-4" style={{ minHeight: "84.41vh" }}>
            <center>
                <form onSubmit={(e) => userLogin(e)} className="text_primary p-4 form-control mt-3">
                    <div className="h1">Login Your Account</div>
                    <br />
                    <br />
                    <input className="form-control" type="text" id="Number" onChange={(e) => setLoginDetails({ ...LoginDetails, Number: e.target.value })} value={LoginDetails.Number} placeholder="Enter Phone Number" />
                    <br />
                    <br />
                    <input className="form-control" type="text" id="Password" onChange={(e) => setLoginDetails({ ...LoginDetails, Password: e.target.value })} value={LoginDetails.Password} placeholder="Enter Password" />
                    <br />
                    <br />
                    <button className="btn bg_primary bg_hover fw-bold">Sing In</button><br />
                    <br />
                    <button onClick={() => {
                        return navigate(Path.register)
                    }} className="btn bg_primary bg_hover fw-bold">Sing Up</button>
                </form>
            </center>
        </div>
    </>
}